<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<exportModal class="ui-exportBrn" v-permission="['pc_assets_callout_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon><Icon icon="UploadOutlined"/></template>
							导出</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" v-permission="['pc_assets_callout_export']" style="margin-right: 16px;" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon><Icon icon="LogoutOutlined"/></template>
							导出全部</a-button>
					</exportModal>
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
					<a-button v-permission="['pc_assets_callout_add']" type="primary" @click="onOut">调出资产</a-button>
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="资产编号" class="ui-form__item">
						<a-row>
							<a-col :span="11">
								<a-input v-model:value="formModal.startCode"></a-input>
							</a-col>
							<a-col :span="2" class="ui-form__middle">到</a-col>
							<a-col :span="11">
								<a-input v-model:value="formModal.endCode"></a-input>
							</a-col>
						</a-row>
					</a-form-item>
					<a-form-item label="价值区间" class="ui-form__item">
						<a-row>
							<a-col :span="11">
								<a-input v-model:value="formModal.startAmount"></a-input>
							</a-col>
							<a-col :span="2" class="ui-form__middle">到</a-col>
							<a-col :span="11">
								<a-input v-model:value="formModal.endAmount"></a-input>
							</a-col>
						</a-row>
					</a-form-item>
					<a-form-item label="资产名称" class="ui-form__item">
						<a-input v-model:value="formModal.assetsName"></a-input>
					</a-form-item>
					<a-form-item label="资产分类" class="ui-form__item">
						<a-cascader v-model:value="formModal.assetsType" :options="$store.state.count.assetClassify" placeholder="请选择资产分类" />
						<!-- <a-select  allow-clear v-model:value="formModal.assetsType" style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.assetClassify" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select> -->
					</a-form-item>
					<a-form-item label="调入单位" class="ui-form__item">
						<a-select showSearch optionFilterProp="label" allow-clear v-model:value="formModal.unitId" style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id" :label="item.name">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="是否已确认" class="ui-form__item">
						<a-select  allow-clear v-model:value="formModal.status" style="width: 190px;">
							<!-- <a-select-option :value="-1">全部</a-select-option> -->
							<a-select-option :value="1">是</a-select-option>
							<a-select-option :value="0">否</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="调出时间段" class="ui-form__item">
						<a-range-picker show-time @change="onChangeFun" :value="clearTime" />
					</a-form-item>
					
					<a-form-item  class="ui-form__item">
						<div style="text-align: center;">
							<a-button @click="onReset">重置</a-button>
							<a-button type="primary" @click="onSearch">搜索</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox :value="'assetsName'">资产名称</a-checkbox>
							<a-checkbox :value="'assetsNum'">资产编号</a-checkbox>
							<a-checkbox :value="'price'">价值</a-checkbox>
							<a-checkbox :value="'time'">调出时间</a-checkbox>
							<a-checkbox :value="'useUnit'">调入单位</a-checkbox>
							<a-checkbox :value="'status'">是否已确认</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.time) }}
						</template>
						<template v-if="column.key === 'status'">
							<div v-if="record.status === 1">是</div>
							<div v-else>否</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-if="record.status === 0" v-permission="['pc_assets_callout_return']" type="link" size="small" @click="onCancel(record)">撤销调出</a-button>
							<div v-else>-</div>
						</template>
					</template>
				</c-Table>
				<!-- <a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.time) }}
						</template>
						<template v-if="column.key === 'status'">
							<div v-if="record.status === 1">是</div>
							<div v-else>否</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-if="record.status === 0" v-permission="['pc_assets_callout_return']" type="link" size="small" @click="onCancel(record)">撤销调出</a-button>
							<div v-else>-</div>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		<outModal ref="outModalRef" @refresh="onRefList"></outModal>
	</div>
</template>

<script>
	import { getTransferListByType, revokeTransfer } from '@/service/modules/property.js';
	import { Icon } from '@/components/icon/icon.js';
	import outModal from './components/outModal.vue';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: { Icon, outModal, cTable, exportModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				searchData: {},
				formModal: {
					type: 0,
				},
				clearTime: [], // 存储时间 用来清空重置
				list: [],
				getDataFun: getTransferListByType,
				type: 'assetsTransferStrategy', // 导出类型
				role: 0, // 导出角色
				condition: '0', // 导出条件
				selIdsData: {},
				sortList:[],
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index'
				}, {
					title: '资产分类',
					align: 'center',
					dataIndex: "assetsType"
				}, {
					title: '资产编号',
					align: 'center',
					dataIndex: "assetsNum"
				}, {
					title: '资产名称',
					align: 'center',
					dataIndex: "assetsName"
				}, {
					title: '价值',
					align: 'center',
					dataIndex: "price"
				},{
					title: '调入单位',
					align: 'center',
					dataIndex: "useUnit"
				}, {
					title: '是否已确认',
					align: 'center',
					key: 'status',
					dataIndex: "status"
				}, {
					title: '调出时间',
					align: 'center',
					key: 'time'
				}, {
					title: '操作',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 120
				}],
			}
		},
		created() {
			this.onSearch()
		},
		methods: {
			onRefList() {
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getTransferListByType({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			type: 0, // 写死 调出
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			console.log("tranlit",ret)
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {}
				this.clearTime = []
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));
				this.searchData.assetsType = this.searchData.assetsType ? this.searchData.assetsType[this.searchData.assetsType.length - 1] : undefined;
				// this.pagination.current = 1;
				this.searchData.type = 0
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onOut() {
				this.$refs.outModalRef.open()
			},
			onCancel(item) {  // 撤销调出
				this.$confirm({
					title: '提示',
					content: "确定撤销调出吗？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await revokeTransfer({
								id: item.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('撤销成功');
								// this.onSearch();
								this.$nextTick(() => {
									this.$refs.cTable.toQuery();
								})
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onChangeFun(date, dateString) { // 借出时间变化的回调
			  this.clearTime = date
			  let starDate = new Date(dateString[0])
			  let endDate = new Date(dateString[1])
			  // console.log(starDate.valueOf(), endDate.valueOf());
			  this.formModal.startTime = starDate.valueOf()/1000
			  this.formModal.endTime = endDate.valueOf()/1000
			},
			onSortClick(type){
				let list = [];
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});
				this.formModal.sort = list;
				console.log(this.formModal);
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>