<template>
	<div>
		<a-modal v-model:visible="visible" width="700px" @cancel="close">
			<template #title>
				<modalHeader title="调出资产"></modalHeader>
			</template>
			<template #footer>
				<a-button @click="close">取消</a-button>
				<a-button @click="onSubmit" type="primary">确认调出</a-button>
			</template>
			
			<a-form ref="form" :model="formModal" :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }">
				<a-form-item name="unitId" label="单位" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-select v-model:value="formModal.unitId" placeholder="请选择单位">
						<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item name="assetsId" label="调出资产" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<assetsSelect v-model:value="formModal.assetsId" @change="onAssetsChange" placeholder="请选择调出资产">
					<!-- <assetsSelect v-model:value="assetsIds" @change="onAssetsChange" placeholder="请选择盘亏资产">	 -->
						<template #value>
							{{ assetsData.name }}
						</template>
					</assetsSelect>
					<!-- <a-select v-model:value="formModal.assetsId" placeholder="请选择调出资产">
					</a-select> -->
				</a-form-item>
				<a-form-item label="出调凭证" class="ui-form__item" >
					<filesUpload v-model:value="formModal.fileList"></filesUpload>
					<!-- <p>注：建议上传pdf格式的文件，大小不超过100M</p> -->
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	import { applyTransfer, getAssetsDetailSearch } from '@/service/modules/property.js';
	import filesUpload from '@/components/upload/filesUpload.vue';
	import modalHeader from '@/components/modalHeader/index.vue';
	import assetsSelect from '@/components/assetsSelect/index.vue';
	export default {
		components: { filesUpload, modalHeader, assetsSelect },
		data() {
			return {
				visible: false,
				assetsData: {},
				formModal: {
					fileList: []
				},
				fileList: []
			}
		},
		methods: {
			async getData(item) {
				this.loading = true;
				try {
					let ret = await getAssetsDetailSearch({
						nameOrCode: item,
					});
					this.loading = false;
					if (ret.code === 200) {
						this.assetsData = ret.data
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSubmit() {
				this.$refs.form.validate().then(async () => {
					let postData = this.$deepClone(this.formModal)
					let tempIds = postData.assetsId
					postData.assetsId = tempIds[0]
					postData.materialUrl = postData.fileList.join(",")
					postData = JSON.parse(JSON.stringify(postData));
					this.loading = true;
					try {
						let ret = await applyTransfer(postData);
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('调出成功');
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			open() {
				this.visible = true;
			},
			close() {
				this.$refs.form.clearValidate();
				this.visible = false;
				this.formModal= {
					// fromUnitId: null,
					fileList: []
				}
			},
			onAssetsChange(assetsData) {
				if(assetsData.length !== 0) {
					this.getData(assetsData[0].code).then(()=>{
						this.$refs.form.clearValidate();
					});
				}else{
					this.assetsData = {}
				}
			}
		}
	}
</script>

<style scoped>
	.ui-box {
		width: 300px;
		padding: 10px;
		margin: 0 0 0 auto;
		background: #F5F7FA;
		border-radius: 10px;
	}
	.ui-result__item {
		margin-bottom: 0;
	}
</style>